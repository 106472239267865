/* eslint-disable no-restricted-syntax */
import { Plugin } from '@nuxt/types';

interface ReplacerObject {
  [key: string]: string;
}

// eslint-disable-next-line no-unused-vars
type I18nFunction = (key: string, replace?: ReplacerObject) => string;

declare module 'vue/types/vue' {
  interface Vue {
    $i18n: I18nFunction;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $i18n: I18nFunction;
  }
  interface Context {
    $i18n: I18nFunction;
  }
}

const i18nPlugin: Plugin = ({ store }, inject) => {
  const i18n: Function = (key: string, replace: ReplacerObject = {}): string => {
    try {
      let translation = key.split('.').reduce((t: any, i: any) => t[i] ?? null, store.state.translations);

      if (!translation) return key;

      for (const placeholder of Object.keys(replace)) {
        translation = translation.replace(`:${placeholder}`, replace[placeholder]);
      }

      return translation;
    } catch {
      return key;
    }
  };

  inject('i18n', i18n);
};

export default i18nPlugin;
